import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { useIntl } from "react-intl";
import { StyledDateRangeFilterWrapper } from "./styled-components/StyledDateRangeFilter";
import { useRef } from "react";
import { dateToString, getDateByDays } from "../../../../utilities/dateUtils";
import {
  StyledDashboardDateFilterOptions,
  StyledDashboardDateFilterOption,
} from "../../Dashboard/components/DashboardDateFilter/styled-components/StyledDashboardDateFilter";
import { DashboardDateFilterOption } from "../../Dashboard/components/DashboardDateFilter/DashboardDateFilter";

interface DateRangeFilterProps {
  id: string;
  dateRange: [Date | null, Date | null];
  setDateRange: any;
  minDate?: Date;
  opcPredefinidas?: boolean;
}

const DateRangeFilter = ({
  dateRange,
  setDateRange,
  minDate,
  opcPredefinidas = true,
}: DateRangeFilterProps) => {
  const intl = useIntl();
  const [startDate, endDate] = dateRange;

  const datePickerRef = useRef<DatePicker>(null);
  const TODAY = new Date();
  const LAST_30_DAYS = getDateByDays(TODAY, -30);

  const options: DashboardDateFilterOption[] = [
    {
      label: `${intl.formatMessage({ id: "today" })} - ${dateToString(TODAY)}`,
      action: () => {
        setDateRange([TODAY, TODAY]);
        if (datePickerRef.current) {
          datePickerRef.current.setOpen(false);
        }
      },
    },
    {
      label: `${intl.formatMessage({ id: "last 30 days" })} - ${dateToString(
        getDateByDays(TODAY, -30)
      )}`,
      action: () => {
        setDateRange([LAST_30_DAYS, TODAY]);
        if (datePickerRef.current) {
          datePickerRef.current.setOpen(false);
        }
      },
    },
  ];

  return (
    <StyledDateRangeFilterWrapper>
      <DatePicker
        showIcon
        ref={datePickerRef}
        dateFormat="dd/MM/yyyy"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M19.3 18.9c.4-.7.7-1.5.7-2.4c0-2.5-2-4.5-4.5-4.5S11 14 11 16.5s2 4.5 4.5 4.5c.9 0 1.7-.2 2.4-.7l3.1 3.1l1.4-1.4l-3.1-3.1m-3.8.1c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5M5 19V9h14v2c.8.5 1.5 1.2 2 2V5c0-1.1-.9-2-2-2h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h5.8c-.6-.6-1-1.2-1.3-2H5M19 5v2H5V5h14Z"
            />
          </svg>
        }
        minDate={minDate}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        isClearable={false}
        locale={es}
        monthsShown={2}
        placeholderText={intl.formatMessage({ id: "enter dates" })}
      >
        <StyledDashboardDateFilterOptions opcPredefinidas={opcPredefinidas}>
          <div className="opciones-predefinidas">
            {options.map((option: DashboardDateFilterOption, index: number) => (
              <StyledDashboardDateFilterOption
                key={`dashboard_date_filter_option_${index}`}
                onClick={option.action}
              >
                {option.label}
              </StyledDashboardDateFilterOption>
            ))}
          </div>
        </StyledDashboardDateFilterOptions>
      </DatePicker>
    </StyledDateRangeFilterWrapper>
  );
};

export default DateRangeFilter;
