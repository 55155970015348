import {
  StyledFormTableFilterInputs,
  StyledFormTableFilterInputWrapper,
} from "../../styled-components/StyledFormTableFilter";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TuneOutlined } from "@mui/icons-material";
import { useFieldArray, useForm } from "react-hook-form";
import {
  ColumnFilter,
  DateFilterOperator,
  IdentifierFilterOperator,
  NumberFilterOperator,
  TableColumn,
  TextFilterOperator,
  ValueType,
} from "../../domain/columns.model";
import { Input, Select } from "../../../Inputs";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import translate from "../../../../../language/translate";
import { getInputTypeFromColumnType } from "../../tableUtils/columnUtils";
import { InputType } from "../../../Inputs/Input";
import { Checkbox } from "../../../Inputs/Checkbox";
import styled from "styled-components";
import DateRangeFilter from "../../../Inputs/DateRangeFilter/DateRangeFilter";
import { useLocation } from "react-router-dom";
import { getDateByDays } from "../../../../../utilities/dateUtils";
import DashboardDateFilter from "../../../Dashboard/components/DashboardDateFilter/DashboardDateFilter";

interface StyledAddFilterContainerProps {
  show: boolean;
}

export const StyledFormTableFilterAddInput = styled.button`
  all: unset;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #9A989E;
  cursor: pointer;
  padding: 5px 0px 5px 0px;
  transition: all 150ms ease-in-out;
  // color: rgb(75, 167, 53);
  &:hover {
    // background: #9A989E;
    background: rgb(75, 167, 53);
    color: white;
  }
`

export const StyledFormTableAddFilter = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: inherit;
  padding: 100px 50px 20px 50px;
  overflow-y: auto;
  color: #9A989E;
`

const StyledTitle = styled.h3`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 4px;
  height: 25px;
  top: 30px;
  left: 30px;
  font-weight: 500;
`;

const StyledApplyButton = styled.button`
  all: unset;
  margin-top: 20px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #9A989E;
  cursor: pointer;
  padding: 5px 0px 5px 0px;
  transition: all 150ms ease-in-out;
  // color: rgb(75, 167, 53);
  &:hover {
    // background: #9A989E;
    background: rgb(75, 167, 53);
    color: white;
  }
`;

const StyledApplyFilterIcon = styled.button`
  all: unset;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  top: 30px;
  right: 30px;
  cursor: pointer;
  border: 1px solid #9A989E;
  transition: background 150ms ease-in-out;
  svg {
    width: 25px;
    height: 25px;
    // color: #9A989E;
    color: rgb(75, 167, 53);
    transition: all 150ms ease-in-out;
  }
  &:hover {
    // background: #9A989E;
    background: rgb(75, 167, 53);
    svg {
      transform: scale(1.1);
      color: ${props => props.theme.base.main};
    }
  }
`

export const StyledAddFilterOptionsContainer = styled.div`
  position: relative;
  display: flex;
  max-height: 400px;
  overflow-y: auto;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.secondary.main};
  border-radius: 8px;
  padding: 10px;
`

const StyledCancelFilterIcon = styled(StyledApplyFilterIcon)`
  right: 30px;
`
const StyledAddFilterContainer = styled.div<StyledAddFilterContainerProps>`
  position: fixed;
  top: 115px;
  border: solid 1px rgba(0,0,0,.1);
  right: 0;
  height: calc(100vh - 115px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  max-width: ${props => props.show ? '60vw' : '0px'};
  z-index: 8;
  background: ${props => props.theme.base.main};
  overflow-y: hidden;
  transition: all 150ms ease-in-out; 
`

const StyledAccordionItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledAccordionButton = styled.button`
  all: unset;
  box-sizing: border-box;
  text-transform: capitalize;
  transition: background-color ease .5s;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIconContainer = styled.div`
  transition: all;
`;

const StyledOptionsContainer = styled.div`
  margin-top: 10px;
`;

const StyledOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ContenedorDateRange = styled.div`
  position: relative;
  width: 100%;
`;

interface AddFilterProps<T> {
  show: boolean;
  columns: TableColumn<T>[];
  filters?: ColumnFilter<ValueType>[];
  onApply: (data: ColumnFilter<ValueType>) => void;
  onCancel: () => void;
}

export function AddFilter<T>({
  show,
  columns,
  onApply,
  onCancel,
}: AddFilterProps<T>) {
  const intl = useIntl();
  const UNDEFINED_COLUMN = "-1";

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    resetField,
    reset,
  } = useForm<ColumnFilter<ValueType>>({
    defaultValues: {
      column: UNDEFINED_COLUMN,
      operator: UNDEFINED_COLUMN,
      values: [{ value: "" }],
    },
    mode: "onSubmit",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "values",
  });

  const selectedColumn = columns.find(
    (col: TableColumn<T>) => col.field === watch("column")
  );
  const operators = selectedColumn?.filterOperators
    ? selectedColumn?.filterOperators?.map((filterOperator) => {
      return {
        label: intl.formatMessage({ id: filterOperator }),
        value: filterOperator,
      };
    })
    : [];
  const watchColumn = watch("column");
  const selectedOperator = watch("operator");
  const watchValues = watch("values");
  const valuesList = fields.map((field, index) => {
    return {
      ...field,
      ...watchValues?.[index],
    };
  });
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const paramFromUrl = queryParams.get("from");
  const paramToUrl = queryParams.get("to");
  const TODAY = new Date();
  const END_OF_THE_THIRD_MONTH_DATE = TODAY;
  END_OF_THE_THIRD_MONTH_DATE.setMonth(
    END_OF_THE_THIRD_MONTH_DATE.getMonth() + 3
  );
  const [selectedDateFilter, setSelectedDateFilter] = useState<Date>(
    END_OF_THE_THIRD_MONTH_DATE
  );
  const LAST_30_DAYS = getDateByDays(TODAY, -30);
  const LAST_YEAR = getDateByDays(TODAY, -365);
  const DEFAULT_START_DATE = paramFromUrl
    ? new Date(`${paramFromUrl} 00:00`)
    : LAST_30_DAYS;
  const DEFAULT_END_DATE = paramToUrl ? new Date(`${paramToUrl} 00:00`) : TODAY;
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    DEFAULT_START_DATE,
    DEFAULT_END_DATE,
  ]);

  const onSubmit = (data: ColumnFilter<ValueType>) => {
    if (selectedOperator === IdentifierFilterOperator.ONLY) {
      const dataValue = selectedColumn?.filterOptions?.find(
        // eslint-disable-next-line eqeqeq
        (option) => option.id == data?.values?.[0].value
      );
      if (dataValue) {
        data.values = [{ value: dataValue }];
      } else {
        data.values = [];
      }
    }
    if (selectedOperator === IdentifierFilterOperator.SOME) {
      const indexList = data?.values
        ?.map((dat, index) => {
          if (dat !== undefined && dat.value === true) {
            return index;
          }
          return undefined;
        })
        .filter((dat) => dat !== undefined);
      const dataValues = indexList
        ?.map((indexData) => {
          if (indexData !== undefined) {
            return { value: selectedColumn?.filterOptions?.[indexData] };
          }
          return undefined;
        })
        .filter((dat) => dat !== undefined);
      if (dataValues) {
        // @ts-ignore
        data.values = [...dataValues];
      }
    }
    onApply(data);
    reset();
  };

  const handleCancel = () => {
    reset();
    onCancel();
  };

  useEffect(() => {
  }, [watchValues])

  useEffect(() => {
    resetField("values");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperator]);

  useEffect(() => {
    // resetField("operator");
    setValue("operator", UNDEFINED_COLUMN);
    resetField("values");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchColumn]);

  useEffect(() => {
    if (selectedOperator === DateFilterOperator.BETWEEN && dateRange) {
      setValue("values", [
        { value: dateRange[0]?.toISOString().split("T")[0] },
        { value: dateRange[1]?.toISOString().split("T")[0] },
      ]);
    } else if (
      selectedOperator === DateFilterOperator.AFTER ||
      selectedOperator === DateFilterOperator.BEFORE ||
      selectedOperator === DateFilterOperator.EQUALS ||
      selectedOperator === DateFilterOperator.NOT_EQUALS
    ) {
      setValue("values", [{ value: selectedDateFilter?.toISOString().split("T")[0] }]);
    }
  }, [dateRange, selectedDateFilter, setValue, selectedOperator]);

  return (
    <StyledAddFilterContainer show={show}>
      <StyledFormTableAddFilter onSubmit={handleSubmit(onSubmit)}>
        <StyledTitle><TuneOutlined/>Filtros</StyledTitle>
        <StyledCancelFilterIcon type="button" onClick={handleCancel}>
          <CloseIcon />
        </StyledCancelFilterIcon>
        {
          columns.map((col, index) =>
            <StyledAccordionItem key={`col-${index}`}>
              {
                <StyledAccordionButton type="button" onClick={() => {watchColumn === col.field ? setValue('column', "-1") : setValue('column', col.field) }} style={{fontWeight: selectedColumn?.field === col.field ? "700" : ""}}>{`— ${col.label}`}<StyledIconContainer style={{transform: selectedColumn?.field === col.field ? "rotate(0)" : "rotate(-90deg)"}}><ExpandMoreIcon/></StyledIconContainer></StyledAccordionButton>
              }
              { selectedColumn?.field === col.field ?
              <StyledOptionsContainer style={{display: selectedColumn?.field === col.field ? "flex" : "none", flexDirection: "column" }}>
                {
                  operators?.map((op) => <StyledOptionContainer>
                    <StyledAccordionButton style={{paddingLeft: "30px", color: selectedOperator === op.value ? "black" : "" , backgroundColor: selectedOperator === op.value ? "#F7F7F7" : ""}} type="button" onClick={() => {selectedOperator === op.value ? setValue("operator", "-1") : setValue('operator', op.value) }}>
                      {op.label}<StyledIconContainer style={{transform: selectedOperator === op.value ? "rotate(0)" : "rotate(-90deg)"}}><ExpandMoreIcon/></StyledIconContainer>
                    </StyledAccordionButton>
                      { selectedOperator === op.value ?
                    <div style={{ display: selectedOperator === op.value ? "flex" : "none", flexDirection: "column" }}>
                      {
                        selectedOperator !== IdentifierFilterOperator.ONLY &&
                        selectedOperator !== IdentifierFilterOperator.SOME &&
                        selectedOperator !== NumberFilterOperator.BETWEEN &&
                        selectedOperator !== DateFilterOperator.BETWEEN &&
                        selectedOperator !== DateFilterOperator.AFTER && 
                        selectedOperator !== DateFilterOperator.BEFORE && 
                        selectedOperator !== DateFilterOperator.EQUALS && 
                        selectedOperator !== DateFilterOperator.NOT_EQUALS
                        ? valuesList.map((field, index) => {
                          return (
                            <StyledFormTableFilterInputWrapper
                              key={`values.${index}.value`}
                            >
                              <Input
                                register={register(`values.${index}.value` as const, {
                                  validate: (value, formValues) => {
                                    return formValues?.values?.[index].value !== "";
                                  },
                                })}
                                name="values"
                                errors={errors}
                                label={`${intl.formatMessage({
                                  id: "value",
                                })} ${valuesList.length > 1 ? index : ""}`}
                                type={getInputTypeFromColumnType(selectedColumn?.type)}
                                width="100%"
                              />
                              {index !== 0
                                      ? (
                                <CloseIcon onClick={() => remove(index)} />
                              ) : null}
                            </StyledFormTableFilterInputWrapper>
                          );
                        })
                        : null}
                      {selectedOperator === TextFilterOperator.CONTAINS ||
                        selectedOperator === TextFilterOperator.DOES_NOT_CONTAIN ? (
                        <StyledFormTableFilterAddInput
                          type="button"
                          onClick={() =>
                            append({
                              value: "",
                            })
                          }
                        >
                          {translate("add value")}
                        </StyledFormTableFilterAddInput>
                      ) : null}
                      {selectedOperator === DateFilterOperator.AFTER || 
                      selectedOperator === DateFilterOperator.BEFORE || 
                      selectedOperator === DateFilterOperator.EQUALS || 
                      selectedOperator === DateFilterOperator.NOT_EQUALS ?
                      <ContenedorDateRange>
                        <DashboardDateFilter
                          selectedDate={selectedDateFilter}
                          setSelectedDate={setSelectedDateFilter}
                          opcPredefinidas={false}
                        />
                      </ContenedorDateRange>
                      :
                      null}
                      {selectedOperator === DateFilterOperator.BETWEEN ? (
                        <ContenedorDateRange>
                          <DateRangeFilter 
                            id="date_range_parameter_input_reconciliation"
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            opcPredefinidas={false}
                          />
                        </ContenedorDateRange>
                        // <>
                        //   <StyledFormTableFilterInputWrapper>
                        //     <Input
                        //       register={register(`values.0.value` as const, {
                        //         validate: (value, formValues) => {
                        //           return formValues?.values?.[0]?.value !== "";
                        //         },
                        //       })}
                        //       name="values"
                        //       errors={errors}
                        //       label={`${intl.formatMessage({
                        //         id: "value",
                        //       })}`}
                        //       type={getInputTypeFromColumnType(selectedColumn?.type)}
                        //       width="100%"
                        //     />
                        //   </StyledFormTableFilterInputWrapper>
                        //   <StyledFormTableFilterInputWrapper>
                        //     <Input
                        //       register={register(`values.1.value` as const, {
                        //         validate: (value, formValues) => {
                        //           return (
                        //             value !== undefined &&
                        //             formValues?.values?.[1]?.value !== ""
                        //           );
                        //         },
                        //       })}
                        //       name="values"
                        //       errors={errors}
                        //       label={`${intl.formatMessage({
                        //         id: "value",
                        //       })}`}
                        //       type={getInputTypeFromColumnType(selectedColumn?.type)}
                        //       width="100%"
                        //     />
                        //   </StyledFormTableFilterInputWrapper>
                        // </>
                      ) : null}
                      {selectedOperator === NumberFilterOperator.BETWEEN ? (
                        <>
                          <StyledFormTableFilterInputWrapper>
                            <Input
                              register={register(`values.0.value` as const, {
                                validate: (value, formValues) => {
                                  return formValues?.values?.[0]?.value !== "";
                                },
                              })}
                              name="values"
                              errors={errors}
                              label={`${intl.formatMessage({
                                id: "value",
                              })}`}
                              type={getInputTypeFromColumnType(selectedColumn?.type)}
                              width="100%"
                            />
                          </StyledFormTableFilterInputWrapper>
                          <StyledFormTableFilterInputWrapper>
                            <Input
                              register={register(`values.1.value` as const, {
                                validate: (value, formValues) => {
                                  return (
                                    value !== undefined &&
                                    formValues?.values?.[1]?.value !== ""
                                  );
                                },
                              })}
                              name="values"
                              errors={errors}
                              label={`${intl.formatMessage({
                                id: "value",
                              })}`}
                              type={getInputTypeFromColumnType(selectedColumn?.type)}
                              width="100%"
                            />
                          </StyledFormTableFilterInputWrapper>
                        </>
                      ) : null}
                      {selectedOperator === IdentifierFilterOperator.ONLY &&
                        selectedColumn?.filterOptions ? (
                        <Select
                          register={register("values.0.value" as const, {
                            validate: (value, formValues) => {
                              return formValues?.values?.[0].value !== "";
                            },
                          })}
                          name="values"
                          errors={errors}
                          nullValue={""}
                          label={intl.formatMessage({ id: "value" })}
                          options={selectedColumn.filterOptions.map((filterOption) => {
                              return {
                            value: filterOption.id,
                            label: filterOption.label,
                              }
                          })}
                          width="100%"
                        />
                      ) : null}
                      {selectedOperator === IdentifierFilterOperator.SOME &&
                        selectedColumn?.filterOptions ? (
                        <StyledAddFilterOptionsContainer>
                          {selectedColumn.filterOptions.map((filterOption, index) => (
                            <Checkbox
                              key={`${selectedColumn.field}_filteroption_${index}`}
                              register={register(`values.${index}.value` as const)}
                              id={`${filterOption.id}`}
                              name="values"
                              label={filterOption.label}
                              type={InputType.CHECKBOX}
                            />
                          ))}
                        </StyledAddFilterOptionsContainer>
                      ) : null}
                    </div>
                      :
                      null
                    }
                  </StyledOptionContainer>
                  )
                }
              </StyledOptionsContainer>
              :
              null
            }
            </StyledAccordionItem>)
        }
        <StyledApplyButton type="submit">
          Filtrar
        </StyledApplyButton>
        <StyledFormTableFilterInputs>
        </StyledFormTableFilterInputs>
      </StyledFormTableAddFilter>
    </StyledAddFilterContainer>
  );
}
