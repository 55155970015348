import Swal from "sweetalert2";
import ReactDOMServer from 'react-dom/server';
import CloseIcon from "@mui/icons-material/Close";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const mostrarMensaje = (icon: any, title: any, text: any) => {
  Swal.fire({
    icon: icon,
    title: title,
    text: text,
    confirmButtonColor: "#008000",
    customClass: {
      popup: "my-swal",
    },
  });
};


const Close = () => {
  const cerrarModal = () => {
    Swal.close();
  };

  return (
    <div className="btn-cerrar-sweet" onClick={cerrarModal}>
       <CloseIcon />
    </div>
  );
};

export const confirmarMensaje = async (
  title: string,
  text: string,
  confirmText: string,
  icon?: any,
  mostrarCancel: boolean = true
) => {
  const result = await MySwal.fire({
    title: (
      <div style={{ display: 'flex',flexDirection: 'column' , alignItems: 'center', justifyContent: 'center' }}>
        <Close/>
        <span style={{ marginLeft: '10px' }}>{title}</span>
      </div>
    ),
    html: text,
    icon: icon,
    showCancelButton: mostrarCancel,
    confirmButtonColor: "tomato",
    confirmButtonText: confirmText,
    cancelButtonText: "Cancelar",
    customClass: {
      popup: "custom-swal-background",
      actions: "swal2-actions-right",
    },
    buttonsStyling: false,
  });

  return result.isConfirmed;
};