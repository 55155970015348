import {
  ColumnFilter,
  ColumnType,
  OrdersColumn,
  Table,
  TableActionsProps,
  TableColumn,
  TableParameters,
  ValueType,
} from "../../Table";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDeleteMenuMutation, useGetMenusQuery } from "../services/menuApi";
import { Menu } from "../domain/Menu.model";
import { useNavigate } from "react-router-dom";
import { StyledMenuTableIcon } from "../styled-components/StyledMenuTableIcon";
import SuspenseLoader from "../../SuspenseLoader/SuspenseLoader";
import translate from "../../../../language/translate";
import { setNotification } from "../../NotificationBar/state/notification.reducer";
import { useAppDispatch } from "../../../../utilities/hooks/redux.hooks";
import FolderIcon from "@mui/icons-material/Folder";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import styled from "styled-components";
import { textTruncate } from "../../../../bankReconciliation/utils/textUtils";
import { InputText } from "primereact/inputtext";
import { FilterTag } from "../../Table/components/TableFilters/FilterTag";
import { Search } from "@mui/icons-material";
import TableFilter from "../../Table/components/TableFilters/TableFilter";
import { AddFilter } from "../../Table/components/TableFilters/AddFilter";
import { Tooltip } from "primereact/tooltip";
import { MultiSelect } from "primereact/multiselect";
import { confirmarMensaje } from "../../../../utilities/mensajeUtils";

function MenusTable() {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ADD_MENU_URL = "/menus/add";
  const EDIT_MENU_URL = "/menus/edit";

  const [filters, setFilters] = useState<any>({
    order: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "parentMenu.label": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "parentMenu.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilter, setGlobalFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const headerTable = [
    {
      header: "Icono",
      field: "icon",
    },
    {
      header: "Orden",
      field: "order",
    },
    {
      header: "Nombre",
      field: "name",
    },
    {
      header: "Etiqueta",
      field: "parentMenu.label",
    },
    {
      header: "Superior",
      field: "parentMenu.name",
    },
  ];
  const [visibleColumns, setVisibleColumns] = useState(headerTable);

  const columnsTable: Array<TableColumn<Menu>> = [
    {
      field: "menuId",
      label: "menuId",
      type: ColumnType.TEXT,
      order: false,
      hidden: true,
    },
    {
      field: "icon",
      label: intl.formatMessage({
        id: "icon",
      }),
      type: ColumnType.TEXT,
      order: true,
      width: "10%",
      hidden: false,
      textAlign: "center",
      render: (row) =>
        row.icon ? (
          <StyledMenuTableIcon src={row.icon} alt={`icon_${row.name}`} />
        ) : (
          <FolderIcon />
        ),
    },
    {
      field: "order",
      label: intl.formatMessage({
        id: "order",
      }),
      type: ColumnType.NUMBER,
      width: "10%",
      order: true,
      hidden: false,
    },
    {
      field: "name",
      label: intl.formatMessage({
        id: "name",
      }),
      type: ColumnType.TEXT,
      order: true,
      width: "25%",
      hidden: false,
    },
    {
      field: "label",
      label: intl.formatMessage({
        id: "label",
      }),
      type: ColumnType.TEXT,
      order: true,
      width: "auto",
      hidden: false,
    },
    {
      field: "father",
      label: intl.formatMessage({
        id: "father",
      }),
      type: ColumnType.NUMBER,
      width: "25%",
      order: false,
      hidden: false,
      render: (row) => (
        <span>
          {typeof row.parentMenu !== "string"
            ? row.parentMenu?.label
            : row.parentMenu ?? "---"}
        </span>
      ),
    },
  ];

  const [parameters, setParameters] = useState<TableParameters<Menu>>({
    page: 1,
    pageSize: 20,
    order: { field: "id", order: OrdersColumn.ASC },
  });

  const tableActions: TableActionsProps<Menu>[] = [
    {
      name: intl.formatMessage({ id: "add" }),
      toExecute: () => {
        navigate(ADD_MENU_URL);
      },
      freeAction: true,
    },
    {
      name: "edit",
      icon: <EditIcon />,
      toExecute: (data) => {
        navigate(`${EDIT_MENU_URL}/${data?.menuId}`);
      },
    },
    {
      name: "delete",
      icon: <DeleteForeverIcon />,
      toExecute: (data) => {
        // eslint-disable-next-line no-restricted-globals
        // if (
        //   !confirm(
        //     intl.formatMessage({
        //       id: "confirm delete?",
        //     })
        //   )
        // ) {
        //   return;
        // }
        // if (data?.menuId !== undefined) {
        //   deleteMenu(data.menuId);
        // }
      },
      color: () => "#ff0000",
    },
  ];

  const { data, isLoading, isError, error } = useGetMenusQuery(parameters);

  const [deleteMenu] = useDeleteMenuMutation();

  const handleParameters = (newParameters: TableParameters<Menu>) => {
    setParameters(newParameters);
  };

  useEffect(() => {
    if (isError) {
      dispatch(
        setNotification({
          show: true,
          severity: "error",
          message: intl.formatMessage({
            // @ts-ignore
            id: error.message ?? error?.data?.msg ?? "error loading data",
          }),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (isLoading) {
    return <SuspenseLoader />;
  }

  if (isError) {
    return <div>{translate("error loading data")}</div>;
  }

  const onFilterChange = (e: any) => {
    const filters = e.filters;

    setFilters(filters);
  };

  const filtroChange = (e: any) => {
    setGlobalFilter(e.target.value);
  };

  const renderIcons = (rowData: any) => {
    return (
      <div>
        <span id={`tooltip-edit-${rowData.menuId}`}>
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={() => editarMenus(rowData)}
          />
        </span>
        <Tooltip
          target={`#tooltip-edit-${rowData.menuId}`}
          content="Editar"
          position="top"
        />

        <span id={`tooltip-delete-${rowData.menuId}`}>
          <DeleteForeverIcon
            style={{ cursor: "pointer" }}
            onClick={() => eliminarMenus(rowData)}
          />
        </span>
        <Tooltip
          target={`#tooltip-delete-${rowData.menuId}`}
          content="Eliminar"
          position="top"
        />
      </div>
    );
  };

  const agregarMenus = () => {
    navigate(ADD_MENU_URL);
  };

  const editarMenus = (rowData: any) => {
    navigate(`${EDIT_MENU_URL}/${rowData?.menuId}`);
  };

  const eliminarMenus = async(rowData: any) => { 
    const confirmar = await confirmarMensaje(
      "Confirmar Eliminar",
      `¿Está seguro que desea eliminar el menu ${rowData.name}?`,
      "Eliminar",
    );
    if (confirmar) {
      deleteMenu(rowData.menuId);
    };
  };

  const handleRemoveFilter = (filterToRemove: ColumnFilter<ValueType>) => {
    const newFilters = parameters?.filters?.filter(
      (filter) => filter !== filterToRemove
    );
    handleParameters({
      ...parameters,
      page: 1,
      filters: newFilters ? [...newFilters] : [],
    });
  };

  const handleAddFilter = (filter: ColumnFilter<ValueType>) => {
    handleParameters({
      ...parameters,
      page: 1,
      filters: parameters?.filters
        ? [...parameters?.filters, filter]
        : [filter],
    });
  };

  const onColumnToggle = (event: any) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = headerTable.filter((col) =>
      selectedColumns.some((sCol: any) => sCol.field === col.field)
    );

    setVisibleColumns(orderedSelectedColumns);
  };

  const formateoRowData = (obj: any, path: string) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  return (
    <>
      {/* <Table<Menu>
        columns={columnsTable}
        data={data}
        parameters={parameters}
        onChangeParameters={handleParameters}
        actions={tableActions}
      /> */}

      <ContenedorBtns>
        <div className="botones">
          <Button onClick={agregarMenus}>
            <i className="pi pi-plus-circle"></i>Agregar
          </Button>
          <MultiSelect
            maxSelectedLabels={2}
            selectedItemsLabel={`${visibleColumns.length} columnas`}
            showSelectAll={false}
            value={visibleColumns}
            options={headerTable}
            optionLabel="header"
            onChange={onColumnToggle}
            className="custom-multiselect multi-custom"
          />
          {parameters?.filters?.map((filter, index) => {
            return (
              <FilterTag<any>
                key={`filtertag_${index}`}
                filter={filter}
                columns={columnsTable}
                onRemove={handleRemoveFilter}
              />
            );
          })}
        </div>
        <div className="buscador">
          <div style={{ display: "flex" }}>
            <Search className="lupa-icono" />
            <InputText
              type="search"
              onInput={(e) => filtroChange(e)}
              placeholder="Búsqueda..."
              className="input-search"
            ></InputText>
          </div>
          {/* <TableFilter openFilter={openFilter} setOpenFilter={setOpenFilter} /> */}
        </div>
      </ContenedorBtns>

      <AddFilter
        show={openFilter}
        columns={columnsTable.filter(
          (column: TableColumn<any>) => !!column.filterOperators
        )}
        filters={parameters.filters}
        onApply={(data: ColumnFilter<ValueType>) => {
          handleAddFilter(data);
          setOpenFilter(!openFilter);
        }}
        onCancel={() => {
          setOpenFilter(!openFilter);
        }}
      />


      <ContenedorTabla>
        <DataTable
          value={data && data.data ? data.data : []}
          paginator
          rows={20}
          rowsPerPageOptions={[20, 50, 100]}
          emptyMessage="No hay registros para mostrar"
          removableSort
          resizableColumns
          filters={filters}
          onFilter={onFilterChange}
          filterDisplay="row"
          globalFilter={globalFilter}
        >
          {visibleColumns.map((col) => (
            <Column
              resizeable
              headerClassName="custom-header"
              className="custom-row"
              key={col.field}
              field={col.field}
              header={col.header}
              sortable
              showFilterMenu={false}
              body={(rowData: any) =>
                col.header === "Icono"
                  ? textTruncate(rowData.icon, 13)
                  : formateoRowData(rowData, col.field)
              }
              filter
              style={{ width: col.header === "icon" ? "15%" : undefined }}
            />
          ))}

          <Column
            resizeable
            headerClassName="custom-header"
            className="custom-row"
            body={(rowData: any) => renderIcons(rowData)}
            header="Acciones"
          />
        </DataTable>
      </ContenedorTabla>
    </>
  );
}

export default MenusTable;

const ContenedorTabla = styled.div`
  background-color: #fff;
  margin: 1rem;
  padding: 1rem;
  border-radius: 5px;
`;

const ContenedorBtns = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 0 1rem;
  max-height: 40px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  background-color: #fff;
  border: none;
  color: #00000099;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    color: #000000d6;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  i {
    margin-right: 5px;
  }
  svg {
    margin-right: 5px;
    width: 20px;
    height: auto;
  }
`;
