import { FieldErrors } from "react-hook-form";
import { InputError } from "./styled-components/InputError";
import {
  DefaultInput,
  InputLayout,
  StyledCheckboxLabel,
  StyledFormLabel,
} from "./styled-components/InputLayout";
import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
        

const formValidation = (
  errors: FieldErrors,
  errorKey: string,
  defaultErrorMessage: string
) => {
  return (
    <InputError show={!!errors[errorKey]}>
      {(errors[errorKey]?.message as string) ?? defaultErrorMessage}
    </InputError>
  );
};
interface InputProps {
  id?: string;
  register: any;
  name: string;
  errors?: FieldErrors;
  label?: string;
  type: InputType;
  disabled?: boolean;
  autoComplete?: string;
  innerHtmlInput?: ReactElement;
  hidden?: boolean;
  width?: string;
  readOnly?: boolean;
  step?: number;
}
export enum InputType {
  NUMBER = "number",
  PASSWORD = "password",
  SEARCH = "search",
  TEXT = "text",
  HIDDEN = "hidden",
  CHECKBOX = "checkbox",
  DATE = "date",
  TEXTAREA = "textarea",
  FILE = "file",
  TIME = "time",
}
export const Input = ({
  id,
  register,
  name,
  errors,
  label = "",
  type,
  disabled = false,
  autoComplete,
  innerHtmlInput,
  hidden,
  width,
  readOnly,
  step,
}: InputProps) => {
  const intl = useIntl();
  const DEFAULT_ERROR_MESSAGE = intl.formatMessage({
    id: "complete this field",
  });
  if (type === InputType.TEXTAREA) {
    const textFieldStyle = { width: width };
    return (
      <InputLayout hidden={hidden} width={width}>
        <TextField
          id={id ?? name}
          {...register}
          name={name}
          disabled={disabled}
          label={label}
          variant="outlined"
          type={type}
          autoComplete={autoComplete}
          readOnly={readOnly}
          fullWidth
          error={!!errors?.[name]}
          helperText={errors?.[name]?.message || DEFAULT_ERROR_MESSAGE}
          InputLabelProps={{
            shrink: true,
          }}
          step={step}
        />
        {errors && formValidation(errors, name, DEFAULT_ERROR_MESSAGE)}
      </InputLayout>
    );
  }
  return innerHtmlInput ? (
    <InputLayout
      disableBorder={type === InputType.CHECKBOX}
      hidden={hidden}
      width={width}
    >
      {React.cloneElement(innerHtmlInput, {
        ...register,
        id,
        name,
        errors,
        label,
        type,
        disabled,
        autoComplete,
        readOnly,
        width,
        hidden,
        step,
      })}
      {errors && formValidation(errors, name, DEFAULT_ERROR_MESSAGE)}
    </InputLayout>
  ) : (
    <InputLayout
      disableBorder={type === InputType.CHECKBOX}
      hidden={hidden}
      width={width}
    >
      {/* ACA EL DEFAULT */}
      {/* {type === "date" ? (
        <Calendar
          disabled={disabled}
          type={type}
          id={id ?? name}
          {...register}
          placeholder={label}
          variant="outlined"
          autoComplete={autoComplete}
          readOnly={readOnly}
          step={step}
          className="calendario-prime-react" 
          dateFormat="dd/mm/yy"
        />
      ) : (
        <InputText
          disabled={disabled}
          type={type}
          id={id ?? name}
          {...register}
          placeholder={label}
          variant="outlined"
          autoComplete={autoComplete}
          readOnly={readOnly}
          step={step}
        />
      )} */}

      <DefaultInput
        disabled={disabled}
        type={type}
        id={id ?? name}
        {...register}
        placeholder={label}
        variant="outlined"
        autoComplete={autoComplete}
        readOnly={readOnly}
        step={step}
      />
      {type === InputType.CHECKBOX ? (
        <StyledCheckboxLabel htmlFor={id}>{label}</StyledCheckboxLabel>
      ) : null}
      {type !== InputType.CHECKBOX ? (
        <StyledFormLabel htmlFor={id ?? name}>{label}</StyledFormLabel>
      ) : null}
      {errors && formValidation(errors, name, DEFAULT_ERROR_MESSAGE)}
    </InputLayout>
  );
};
export default Input;
