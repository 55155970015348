import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
 

  * {
    margin: 0;
    box-sizing: border-box;
  };



  .p-button-outlined span, .p-column-filter-matchmode-dropdownm, .p-column-filter-operator, .p-column-filter-matchmode-dropdown, .p-column-filter-add-rule{
    display: none !important;
  };


  .p-column-filter-buttonbar button{
    padding: 10px;
    margin-top: -15px;
    background-color: red;
    border: none;
    color: #fff;
    background-color: #2616F2;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  };

  .p-column-filter-buttonbar .p-button-outlined{
    padding: 5px 10px;
    margin: 0;
    background-color: red;
    border: none;
    color: #fff;
    background-color: tomato;
    display: none;
  };

  .p-button-sm::after{
    content: 'Buscar';
    display: block;
    text-align: center;
    color: inherit;
  };

  .p-button-outlined::after {
    content: 'Limpiar';
    display: block;
    text-align: center;
    color: inherit;
  };

  .p-checkbox-box{
    background-color: #fff !important;
  };

  .p-checkbox-box svg{
    color: #000 !important;
  };

  .p-checkbox input{
    color: red !important;
    background-color: red !important;
  }

  .p-filter-column{
    padding: 10px 0;
  }

  .p-column-filter {
    padding: 6px 5px !important;
    min-width: 50px;
    z-index: 1 !important;
    width: 100% !important;
  };

  .p-column-filter input{
    padding: 5px 5px !important;
    min-width: 50px;
  }

  .p-dropdown-item:hover {
  background-color: transparent !important;
}

.p-dropdown-item.p-highlight {
  background-color: transparent !important;
  color: inherit !important;
}

.p-dropdown-item.p-highlight:focus {
  outline: none !important;
}

.p-dropdown {
  border: 1px solid #acacac; 
  border-radius: 5px; 
}


.p-dropdown.p-focus {
  border: 1px solid #acacac !important; 
}

.p-dropdown.p-focus {
  box-shadow: none !important;
}

  body {
    margin: 0;
    font-family: 'Verdana', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif !important;-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .Titulo-compuesto {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .checkDiaries,
  .checkExtract {
    position: absolute;
    left: 20px;
    top: 15px;
    z-index: 1 !important;
  }

  .p-column-filter-clear-button svg{
    visibility: visible !important;
  }
  .custom-header {
    background-color: #f2f3fa;
    color: #4d4949f8;
    padding: 15px 5px !important;
    border-top: 2px dotted #cccccc99;
    border-bottom: 2px dotted #cccccc99;
    font-weight: bold;
    text-align: center !important;
    /* position: relative !important; */
    :first-child {
      border-radius: 5px 0px 0px 5px;
      border-left: 2px dotted #cccccc99;
    }
    :last-child {
      border-radius: 0px 5px 5px 0px;
      border-right: 2px dotted #cccccc99;
    }
    svg{
      width: 25px;
      height: 23px;
      color: #706F6F;
      transition: 0.5s ease-in-out;
      padding: 3.5px;
    } 
    :hover{
      svg{
        border-radius: 5px;
      }
    }
    .settings-icon{
      color: #ACACAC;
      text-align: center;
      opacity: 1 !important;
      font-size: 50px !important;
      padding: 0 !important;
    }
  }
  .custom-row{
    user-select: text;
    text-align: center;
    svg{
      color: #ACACAC;
      cursor: pointer;
      font-size: 26px;
      padding: 1px 2px;
      transition: 0.5s;
    }
    svg:hover{
      border-radius: 5px;
      font-size: 26px;
      padding: 0 2px;
      background: rgba(0,0,0,0.1);
    }
    border-right: 2px dotted #cccccc99;
    border-bottom: none;
    padding: 0 10px;
    :last-child{
      border-right: none;
    }
  }
  .botones{
    display: flex;
    gap: 1rem;
  }
  .buscador{
    display: flex;
    gap: 1rem;
    
    align-items: center;
    .input-search{
      width: 100%;
      padding: 10px 10px 10px 40px;
      transition: 0.5s;
      &:focus{
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
        outline: none;
      }
      &:hover{
        outline: none;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
        border: 1px solid rgba(0,0,0,0.3);
      } 
    }
    .lupa-icono{
      position: relative;
      left: 40px;
      color: #ACACAC;
      padding: 0 5px;
      font-size: 40px;
    }
  }

  .p-fluid,
  .p-link {
    background-color: transparent;
  }

  .p-paginator-page.p-highlight {
    color: #565E6C !important;
    font-weight: 800 !important;
  }

  
  .custom-multiselect {
  max-width: 200px;
  min-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #00000099;
  font-size: 14px !important;
  font-weight: 400;
  svg {
    color: #00000099;
    font-size: 14px !important;
    font-weight: 300;
  }
}

 
.custom-multiselect .p-multiselect {
  max-width: 100%;
}
 
.custom-multiselect .p-multiselect-label-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
 
.custom-multiselect .p-multiselect-label {
  white-space: nowrap;
}
 
.p-multiselect-item{
  background-color: transparent;

  span{
    color: #000000d6;
  }
}


  .p-datatable-tbody td {
    user-select: text;
    padding: 5px 0;
    min-height: 40px !important;
    height: 40px !important;
    max-height: 40px !important;
    .tacho {
      width: 50%;
      cursor: pointer;
    }
    .tacho svg {
      transform: translate(-30%, 0);
    }
    .LockClock {
      width: 50%;
      transform: translate(-20%, 0);
    }
  }

  .p-datatable {
    padding: 0 8px;
  }
  .p-filter-column {
    padding: 5px 0;
  }
  .p-column-header-content span svg {
    margin-left: 5px;
    color: #4d4949f8;
  }
  .p-datatable-thead tr th {
    white-space: nowrap;
    .p-column-header-content {
      justify-content: center;
    }
  }
  td {
    text-align: center;
  }

  .p-multiselect-checkbox{
    margin-right: 5px;
  }

  .p-checkbox-box[data-p-highlight="true"] {
  /* Estilos cuando data-p-highlight es "true" */
  background-color: #64b33b !important;
  color: #fff !important;
  border: solid 1px #64b33b !important;
}

.p-checkbox-box[data-p-highlight="false"] {
  /* Estilos cuando data-p-highlight es "false" */
  background-color: #fff !important;
  color: #000 !important;
}

.p-checkbox-box[data-p-highlight="true"] svg {
  color: #fff !important;
}


.p-checkbox-box{
  transition: none;
  border: solid 1px rgba(0, 0, 0, 0.3);
}

.custom-swal-background {
    width: 800px;
    position: relative;
}

.btn-cerrar-sweet {
    color: rgb(255, 255, 255);
    background-color: rgba(77, 73, 73, 0.357);
    border-radius: 100%;
    padding: 1px;
    font-size: 1.3rem;
    cursor: pointer;
    width: 30px;
    height: 30px;
    font-weight: 100;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 999 !important;
}


.swal2-actions-right {
  justify-content: flex-end;
}
 
.swal2-confirm {
  margin-left: 10px;
}
 
 
.swal2-confirm {
  padding: 7px 18px;
  margin: 0 5px;
  background-color: #64b33b;
  border: 1px solid #64b33b;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
  font-weight: 600;
}
 
.swal2-cancel {
  padding: 7px 18px;
  margin: 0 10px;
  background-color: #fff;
  border: 1px solid #fff;
  color: #e30613;
  border-radius: 4px;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
  font-weight: 600;
}

.p-calendar, .p-inputtext {
  box-shadow: none !important;
  border: solid 1px rgba(0, 0, 0, 0.2) !important;
  outline: none !important;
}

.p-calendar input:hover,
.p-calendar input:focus,
.p-calendar input:active{
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.p-calendar:not(:hover) .p-inputtext,
.p-calendar:not(:focus) .p-inputtext {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}


.p-datepicker:hover,
.p-datepicker:focus{
  .p-calendar input{
    background-color: red !important;
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
  }
}

.p-inputtext:hover,
.p-inputtext:focus,
.p-inputtext:active {
  outline: none !important;
  box-shadow: none !important;
  border-color: inherit !important; 
  border: solid 1px rgba(0, 0, 0, 0.2) !important;
}

.p-dropdown {
  border: solid 1px rgba(0, 0, 0, 0.2) !important;
}

.p-dropdown .p-dropdown-label {
  border: none !important;
}

.p-dropdown:focus {
  border-color: none !important; 
  box-shadow: none !important; 
}

.p-dropdown.p-disabled {
  border-color: none !important;  
}

.p-dropdown:hover {
  border-color: none !important; 
}

.p-dropdown-item:hover {
  background-color: transparent !important;
  color: inherit !important; 
  box-shadow: none !important; 
  border: none !important;  
}

.p-dropdown-item:hover{
  background-color: #efefef !important;
  color: inherit !important; 
  box-shadow: none !important; 
  border: none !important;  
}
`;

export default GlobalStyles;
