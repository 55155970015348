import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@mui/material';

function SuspenseLoader() {
  //esto es el spinner chico de la punta superior derecha (azul)
  // useEffect(() => {
  //   NProgress.start();

  //   return () => {
  //     NProgress.done();
  //   };
  // }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 20,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backdropFilter: "blur(7px)",
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress style={{ color: '#4BA735' }}  size={64} disableShrink thickness={3} />
    </Box>
  );
}

export default SuspenseLoader;
